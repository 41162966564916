@import "color-palette";

.CBButton {
    cursor: pointer;
    position: fixed;
    left: 0;
    padding-left: 20px;
    bottom: 10vh;
    width: 80px;
    height: 55px;
    border: 3px solid $color-text-aqua;
    border-left: none;
    border-top-right-radius: 27.5px;
    border-bottom-right-radius: 27.5px;
    z-index: 99999;
    background-color: rgba($color-text-white, 0.09);

    svg {
        position: relative;
        right: 7px;
        top: 1px;
    }

    &:hover {
        border: 3px solid $color-bg-white;
        border-left: none;
        background-color: rgba($color-primary-blue, .4);
    }
}