@import "color-palette";
@import "vars";
@import "breakpoints";

.ProductChartLine {
    border-radius: $border-radius-12;
    border: 1px solid $color-border-chart;
    background: $color-bg-white;
    height: 80px;
    display: flex;
    width: 100%;
    padding: 10px 0;
}

.Col {
    flex-grow: 1;
}

.Col:nth-child(1) {
    $baseWidth: 335px;
    $gap: 25px;
    max-width: calc($baseWidth + $gap);
    padding: 0 20px;
    display: flex;
    justify-content: center;

    > div {
        margin: 0;
        margin-left: $gap;
        display: flex;
        align-items: center;

        > span {
            margin: 0;
            padding: 8px;
            border-radius: $border-radius-8;
            background: $color-bg-chart;
            color: $color-primary-black;
            font-size: 13px;
            font-weight: $font-weight-semibold;
            line-height: normal;
        }
    }

    @media (max-width: $breakpoint-mobile-chart-line) {
        max-width: 200px;

        > div {
            margin-left: 0;
        }
    }

    @media (max-width: $breakpoint-mobile-sm) {
        display: none;
    }
}

.BiasDisplayed {
    .Col:nth-child(1) {
        @media (max-width: $breakpoint-mobile-chart-line) {
            max-width: 210px;

            h3 {
                display: none;
            }

            > div {
                margin-left: 0;
            }
        }

        @media (max-width: $breakpoint-mobile-sm) {
            display: none;
        }
    }
}

.Col:nth-child(2) {
    padding: 0 20px;
    border-left: solid 0.5px $color-divider-border;
    border-right: solid 0.5px $color-divider-border;

    @media (max-width: $breakpoint-mobile-sm) {
        border: none;
    }
}

.Col:nth-child(3) {
    @media (max-width: $breakpoint-tablet-chart-line) {
        display: none;
    }

    padding: 0 20px;
    max-width: 300px;
    display: flex;
    align-items: center;

    p {
        color: $color-primary-black;
        font-size: 12px;
        font-weight: $font-weight-medium;
        line-height: normal;
    }
}

.SecondCol {
    .HeaderTexts {
        width: 100%;
        display: flex;
        justify-content: space-between;

        margin-bottom: 10px;

        > span {
            color: $color-text-accent;
            font-size: 13px;
            font-weight: $font-weight-medium;
            line-height: normal;
        }
    }
}

.ProgressBar {
    position: relative;

    .BGLine {
        position: absolute;
        border-radius: $border-radius-100;
        background: $color-bg-bar;
        width: 100%;
        height: 10px;
    }

    $width: 60%;

    .GreenLine {
        position: absolute;
        border-radius: $border-radius-100;
        background: $color-bg-green;
        width: $width;
        height: 10px;
        left: calc(50% - calc($width / 2));
    }

    .TextFrom {
        top: 20px;
        width: 140px;
        color: $color-primary-black;
        font-size: 13px;
        font-weight: $font-weight-bold;
        line-height: normal;
        position: absolute;
        left: calc(calc(50% - calc($width / 2)) - 70px);
        text-align: center;
    }

    .TextTo {
        top: 20px;
        width: 140px;
        color: $color-primary-black;
        font-size: 13px;
        font-weight: $font-weight-bold;
        line-height: normal;
        position: absolute;
        left: calc(calc(50% + calc($width / 2)) - 70px);
        text-align: center;
    }

    .SeparatorFrom,
    .SeparatorTo {
        width: 0.5px;
        height: 8px;
        background: $color-divider-border;
        position: absolute;
        top: 12px;
    }

    .SeparatorFrom {
        left: calc(50% - calc($width / 2));
    }

    .SeparatorTo {
        left: calc(50% + calc($width / 2));
    }

    .Cursor {
        z-index: 9999;
        position: absolute;
        top: -8px;
    }
}
