/*
* По возможности наследуем стили из горизонтальной карточки
*/
@import "./../CatalogCard/CatalogCard.module.scss";

@import "color-palette";
@import "mixins";
@import "zIndexes";

$headerHeight: 52px;

.MainLink:hover {
    text-decoration: none;

    .VerticalCardName {
        text-decoration: underline;
    }
}

.Root {
    position: relative;
}

.DisabledText {
    z-index: $disabledText;
    position: absolute;
    width: 138px;
    height: 32px;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-bg-hidden;
    border-radius: 6px;
    font-weight: $font-weight-medium;
    font-size: 13px;
    line-height: 16px;
    color: $color-text-white;
}

.Disabled {
    filter: opacity(0.3);
    cursor: unset !important;

    * {
        cursor: unset !important;
    }

    *:hover {
        cursor: unset !important;
        text-decoration: none;
    }
}

.VerticalCatalogCard {
    border: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    min-height: 480px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    position: relative;

    &:not(.DisableShadows):hover {
        box-shadow: $shadow-large-faint;
        border-radius: $border-radius-12;

        @media screen and (min-width: $breakpoint-mobile-md-sm) {
            position: relative;
            z-index: 1;
            transform: scale(1.01);
            transform: translateY(-5px);
        }
    }
}

.VerticalCatalogCardMap {
    border: none;
    min-height: 480px;
    height: fit-content;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    .Prev {
        left: 16px;
        top: calc(50% - 16px);
    }

    .Next {
        right: 16px;
        top: calc(50% - 16px);
    }

    .Prev,
    .Next {
        position: absolute;
        z-index: $galleryButtonsZIndex;
        cursor: pointer;
    }

    .VerticalCardContent {
        border: none;
        height: calc(100% - 215px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 10px 15px 0;
    }

    .Props {
        .Prop {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            .PropValue {
                font-weight: $font-weight-semibold;
                font-size: 14px;
                line-height: 17px;
                color: $color-primary-black;
            }

            .PropLabel {
                font-weight: $font-weight-medium;
                font-size: 14px;
                line-height: 17px;
                color: $color-text-accent;
            }
        }
    }

    .Divider {
        padding: 0;
        margin: 16px 0;
        border-bottom: 0.5px solid $color-divider-border;
        width: 100%;
        box-sizing: border-box;
        height: 1px;
    }

    .Location {
        margin: 0;
        padding: 0;
        position: relative;
        top: 2px;
    }

    .MapButtonsContainer {
        width: 100%;
        margin-bottom: 30px;
    }

    .DetailedInfo {
        > * {
            margin-bottom: 20px;
        }
    }

    .Footer {
        margin-bottom: 15px;
    }
}

.LinkBtn {
    height: 56px;

    background: $color-bg-white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-text-aqua;
}

.VerticalCardContent {
    border: 1px solid $color-border-catalog;
    height: calc(100% - 215px);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 10px 15px 0;
}

.VerticalCardNameContainer {
    height: 46px;
    display: flex;
    align-items: center;
}

.VerticalCardName {
    @extend .Name;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    display: box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Img {
    width: 100%;
    height: 215px;

    img {
        width: 100%;
        height: 215px;
        object-fit: cover;
    }
}

.Price {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 10px;

    &__source {
        font-weight: $font-weight-bold;
    }

    &__usd {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-semibold;
        color: $color-text-accent;
        margin-left: 6px;
    }
}

.Tags {
    font-weight: $font-weight-medium;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;

    & span:empty {
        display: inline-block;
        width: 50px;
        border-radius: 4px;
        background-color: $color-bg-grey-dark;
        opacity: .15;
        height: 14px;
    }
}

.MyAdsInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    &__views {
        font-size: 16px;
        font-weight: $font-weight-medium;
        line-height: 20px;
        margin-left: 6px;
    }
}

.Props {
    min-height: 64px;
    @include list;

    li {
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 16px;

        &:empty {
            width: 120px;
            background-color: $color-bg-grey-dark;
            opacity: .15;
            height: 10px;
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    margin-bottom: 13px;
}

.Location {
    height: 15px;
    font-weight: $font-weight-medium;
    font-size: 11px;
    line-height: 13px;
    padding-left: 2px;
    margin-bottom: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    display: block;

    @media (max-width: $breakpoint-tablet-xs) {
        font-size: 12px;
        line-height: 14px;
    }

    & span:empty {
        display: inline-block;
        width: 90%;
        background-color: $color-bg-grey-dark;
        opacity: .15;
        height: 10px;
        border-radius: 4px;
    }
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;

    &_icon {
        width: 66px;
        max-height: 22px;
    }

    .UpdatedTime {
        font-weight: $font-weight-medium;
        font-size: 11px;
        line-height: 13px;
        color: $color-text-accent;

        @media (max-width: $breakpoint-tablet-xs) {
            font-size: 12px;
            line-height: 14px;
        }
    }
}

.SecondFooter {
    margin-bottom: 16px;
}

.FavoriteBlock {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: $favoriteIconZIndex;
    cursor: pointer;

    &:hover {
        svg {
            fill: $color-bg-blue;
        }
    }

    &:active {
        animation: pulse 0.3s ease;
    }
}

.CompareOffer {
    width: 36px;
    height: 36px;
    border: none;
    background-color: $color-bg-black-light;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 52px;
    right: 10px;
    z-index: $compareIconZIndex;
    cursor: pointer;

    &:active {
        animation: pulse 0.3s ease;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
