@import 'zIndexes';
@import 'color-palette';
@import "vars";

.Gallery {
    width: 100%;
}

.GalleryCont {
    width: 100%;
    max-height: 500px;
}

.Prev {
    left: 16px;
    top: calc(50% - 16px);
}

.Next {
    right: 16px;
    top: calc(50% - 16px);
}

.Prev,
.Next {
    position: absolute;
    z-index: $galleryButtonsZIndex;
    cursor: pointer;
}

.Thumbnails {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 16px;

    &__item {
        max-width: 140px;
        width: 100%;
        height: 100px;
        background: $color-bg-logo;
        border-radius: $border-radius-6;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    &__layer {
        position: absolute;
        background: $color-bg-smoke-grey;
        max-width: 140px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: $border-radius-6;
        cursor: pointer;

        &--label {
            display: block;
            font-weight: $font-weight-semibold;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $color-text-white;
            cursor: pointer;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius-6;
        cursor: pointer;
    }
}

.FullscreenImageIndex {
    position: absolute;
    width: calc(100% - 45px);
    display: flex;
    justify-content: space-between;
    margin: 20px;

    .ImageIndex {
        padding: 10px 20px;
        background: $color-bg-smoke-grey;
        color: $color-text-white;
        border-radius: $border-radius-5;
        font-weight: $font-weight-semibold;
    }
}

.PrevBtn,
.NextBtn {
    z-index: 9999;
    cursor: pointer;
}

.CloseIconCont {
    position: relative;
    width: 32px;
    height: 32px;
}

.CloseIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.CloseIcon:hover {
    opacity: 1;
}

.CloseIcon:before,
.CloseIcon:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: $color-bg-graphite;
}

.CloseIcon:before {
    transform: rotate(45deg);
}

.CloseIcon:after {
    transform: rotate(-45deg);
}